import { render, staticRenderFns } from "./Contact.vue?vue&type=template&id=7dae6855&scoped=true&"
import script from "./Contact.vue?vue&type=script&lang=ts&"
export * from "./Contact.vue?vue&type=script&lang=ts&"
import style0 from "./Contact.vue?vue&type=style&index=0&id=7dae6855&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7dae6855",
  null
  
)

/* custom blocks */
import block0 from "./Contact.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports
import QPage from 'quasar/src/components/page/QPage.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QPage,QAvatar});
