













































import { Component, Vue } from "vue-property-decorator";

@Component({})
export default class HelpView extends Vue {
  public $store: any;
}
